import { TenantLicenseStatus } from "app/store/tenants";
import { getReadableDate } from "app/utils/dateUtils";
import { useGetHealthCheckQuery } from "appv2/api/health-checks/health-checks.api";
import { useCurrentUserRoles } from "appv2/hooks/use-current-user-roles.hook";
import { useURLParamDisplayMode } from "appv2/hooks/use-display-mode.hook";
import { useIsMobile } from "appv2/hooks/use-is-mobile.hook";
import { useGetTenantLicense } from "appv2/pages/beacon/health-checks/use-get-tenant-license.hook";
import { useHealthCheckRouteParams } from "appv2/pages/beacon/health-checks/use-health-check-route-params.hook";
import { useIntl } from "react-intl";
import { useHealthCheckAnnotationCounts } from "../../health-check/use-health-check-annotation-counts.hook";
export var useHealthCheckActivity = function () {
    var _a, _b;
    var intl = useIntl();
    var _c = useHealthCheckRouteParams(), _d = _c.healthCheckGuid, healthCheckGuid = _d === void 0 ? "" : _d, tenantGuid = _c.tenantGuid, userType = _c.userType;
    var isMobile = useIsMobile();
    var displayMode = useURLParamDisplayMode().displayMode;
    var _e = useGetHealthCheckQuery([userType, healthCheckGuid, tenantGuid]), data = _e.data, isHealthCheckLoading = _e.isLoading, isSuccess = _e.isSuccess, isError = _e.isError;
    var _f = useCurrentUserRoles(), currentUserIsBeaconUser = _f.currentUserIsBeaconUser, currentUserIsAdmin = _f.currentUserIsAdmin;
    var hasOrchestryUsage = !!((_a = data === null || data === void 0 ? void 0 : data.MetricsData) === null || _a === void 0 ? void 0 : _a.OrchestryUsage) && (data === null || data === void 0 ? void 0 : data.ReportTenantLicenseStatus) !== TenantLicenseStatus.Beacon;
    var _g = useGetTenantLicense(), tenantLicense = _g.tenantLicense, getTenantLicenseIsLoading = _g.isLoading;
    var showBeaconPaywall = currentUserIsBeaconUser ||
        (userType === "partner" && tenantLicense === TenantLicenseStatus.Beacon) ||
        //the PDF does not have access to the tenant GUID, so we need to check the license status from the health check data
        //This means that after a client upgrades, their old HCs will still have paywalls from when they were unlicensed.
        (displayMode === "pdf" && (data === null || data === void 0 ? void 0 : data.ReportTenantLicenseStatus) === TenantLicenseStatus.Beacon);
    var showBeaconSavings = (data === null || data === void 0 ? void 0 : data.ReportTenantLicenseStatus) === TenantLicenseStatus.Beacon;
    var hasLeaderboard = !!((_b = data === null || data === void 0 ? void 0 : data.MetricsData) === null || _b === void 0 ? void 0 : _b.Leaderboard);
    var name = data === null || data === void 0 ? void 0 : data.Name;
    var title = name !== null && name !== void 0 ? name : intl.formatMessage({ id: "page.health_check.title", defaultMessage: "{date} Health Check" }, { date: getReadableDate(data === null || data === void 0 ? void 0 : data.GeneratedDate) });
    var shouldRenderSubtext = displayMode === "pdf";
    var showViewDetailsButton = displayMode !== "pdf" && !tenantGuid && currentUserIsAdmin;
    var showHealthCheckNotFoundPage = !isHealthCheckLoading && !data && !isSuccess;
    var _h = useHealthCheckAnnotationCounts(), totalAnnotationCount = _h.totalAnnotationCount, isAnnotationsLoading = _h.isLoading;
    return {
        intl: intl,
        data: data !== null && data !== void 0 ? data : { MetricsData: {} },
        startDate: getReadableDate(data === null || data === void 0 ? void 0 : data.StartDate),
        endDate: getReadableDate(data === null || data === void 0 ? void 0 : data.EndDate),
        title: title,
        isLoading: isHealthCheckLoading || getTenantLicenseIsLoading,
        hasOrchestryUsage: hasOrchestryUsage,
        showBeaconPaywall: showBeaconPaywall,
        showBeaconSavings: showBeaconSavings,
        isMobile: isMobile,
        hasLeaderboard: hasLeaderboard,
        healthCheckGuid: healthCheckGuid,
        shouldRenderSubtext: shouldRenderSubtext,
        status: data === null || data === void 0 ? void 0 : data.Status,
        healthCheckBrandingImage: data === null || data === void 0 ? void 0 : data.HealthCheckReportBrandingImage,
        partnerBrandingImage: data === null || data === void 0 ? void 0 : data.PartnerBrandingImage,
        tenantGuid: tenantGuid,
        clientDownloadLink: data === null || data === void 0 ? void 0 : data.ClientDownloadLink,
        partnerDownloadLink: data === null || data === void 0 ? void 0 : data.PartnerDownloadLink,
        showHealthCheckNotFoundPage: showHealthCheckNotFoundPage,
        showViewDetailsButton: showViewDetailsButton,
        tenantName: data === null || data === void 0 ? void 0 : data.TenantName,
        totalAnnotationCount: totalAnnotationCount,
        isAnnotationsLoading: isAnnotationsLoading,
        displayMode: displayMode
    };
};
