import { getReadableDate } from "app/utils/dateUtils";
import { RecommendationRecordVisibility } from "appv2/api/recommendations/recommendation-record-visibility.type";
import moment from "moment";
var NUMBER_OF_DAYS_TO_CONSIDER_NEW = 30;
export var wasRecommendationRecentlyOpened = function (recommendation) {
    return moment(recommendation.RecommendationRecord.RecommendationLastSetAsOpen).isSameOrAfter(moment().subtract(NUMBER_OF_DAYS_TO_CONSIDER_NEW, "days"));
};
export var isRecommendationNewlyLaunched = function (recommendation) {
    if (recommendation.Recommendation === null)
        return false;
    return moment(recommendation.Recommendation.CreatedDate).isSameOrAfter(moment().subtract(NUMBER_OF_DAYS_TO_CONSIDER_NEW, "days"));
};
export var determineDateString = function (currentTab, record) {
    var _a;
    switch (currentTab) {
        case RecommendationRecordVisibility.Visible:
        case null: //No tab set if on Health Checks Additions tab.
            return "Last Opened: ".concat(getReadableDate(new Date(record.RecommendationLastSetAsOpen)));
        case RecommendationRecordVisibility.Snoozed:
        case RecommendationRecordVisibility.Resolved:
        default:
            return "Last Updated: ".concat(getReadableDate(new Date((_a = record.UpdatedDate) !== null && _a !== void 0 ? _a : "")));
    }
};
